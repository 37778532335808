var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"ml-table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":50},scopedSlots:_vm._u([{key:"item.quote_description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quote_description)+" ")]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [(item.order)?_c('v-chip',{staticClass:"routerLink",attrs:{"color":"blue","dark":"","small":"","to":{ name: 'order.show', params: {id: item.order.id}},"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('order.view_order'))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status === 'Offered')?_c('v-chip',{staticClass:"routerLink",attrs:{"color":_vm.getColor(item.status),"dark":"","small":"","to":{ name: 'showcasework-details.show', params: {id: item.product.id}},"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('quote.view_offer'))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(1==2)?_c('v-chip',{attrs:{"small":"","label":"","text-color":_vm.getColor(item.status)}},[_vm._v(_vm._s(item.status)+" ")]):_vm._e(),_c('span',{staticClass:"text-xs font-weight-light red--text",class:_vm.getColorClass(item.status)},[_vm._v(_vm._s(item.status))])]}},{key:"item.product.price",fn:function(ref){
var item = ref.item;
return [(item.product)?_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.product.price)))]):_vm._e()]}},{key:"item.product.package_discount",fn:function(ref){
var item = ref.item;
return [(item.product)?_c('span',[_vm._v(_vm._s(item.product.discount)+"%")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }