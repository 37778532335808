<template>
  <v-card elevation="0">
    <v-card-title>

      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>

    </v-card-title>

    <v-card-text>

      <v-data-table
          dense
          :headers="headers"
          :items="data"
          :search="search"
          :items-per-page="50"
          class="ml-table"
      >
        <template v-slot:[`item.quote_description`]="{ item }">
          {{ item.quote_description }}
        </template>

        <!--
        <template v-slot:[`item.products`]="{ item }">
          <div v-if="item.products">
            <div v-for="(product,index) in item.products" :key="index">
              {{product.name}} / {{product.price | currency}} ({{product.package_discount/100 | percent}})
            </div>
          </div>
        </template>
        -->

        <!--
        <template v-slot:[`item.offer_description`]="{ item }">
          {{item.offer_description | truncate(300)}}
        </template>
        -->
        <!--
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.status != 'Approved' && item.status != 'Rejected'"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          v-if="item.status != 'Approved' && item.status != 'Rejected'"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>

      </template>
       -->
        <template v-slot:[`item.order`]="{ item }">
          <v-chip color="blue" dark
                  v-if="item.order"
                  small
                  :to="{ name: 'order.show', params: {id: item.order.id}}"
                  class="routerLink"
                  target="_blank"
          >{{ $t('order.view_order') }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-chip :color="getColor(item.status)" dark
                  v-if="item.status === 'Offered'"
                  small
                  :to="{ name: 'showcasework-details.show', params: {id: item.product.id}}"
                  class="routerLink"
                  target="_blank"
          >{{ $t('quote.view_offer') }}
          </v-chip>

        </template>
        <template v-slot:[`item.status`]="{ item }">

          <v-chip
              v-if="1==2"
              small
              label
              :text-color="getColor(item.status)"
          >{{ item.status }}
          </v-chip>
          <span class="text-xs font-weight-light red--text" :class="getColorClass(item.status)">{{ item.status }}</span>

        </template>

        <template v-slot:[`item.product.price`]="{ item }">
          <span v-if="item.product">{{item.product.price | currency}}</span>
        </template>

        <template v-slot:[`item.product.package_discount`]="{ item }">
          <span v-if="item.product">{{item.product.discount}}%</span>
        </template>


      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  middleware: ['auth', 'user'],
  data() {
    return {
      response: [],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        status: ''
      },
      defaultItem: {
        status: ''
      },
      loading: false,
      data: [],
      search: '',
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Vendor', value: 'vendor.name'},
        {
          text: 'Quote Request',
          sortable: false,
          value: 'quote_description'
        },
        {text: 'Request Date', value: 'created_at'},
        {text: 'Package', value: 'product.name'},
        {text: 'Price', value: 'product.price'},
        {text: 'Discount', value: 'product.package_discount'},
        {text: 'Order', value: 'order'},
        {text: 'Status', value: 'status'},
        {text: 'Actions', value: 'actions'}
      ],
    }
  },
  mounted() {
    this.getQuotes();
  },
  methods: {
    getColor(status) {
      if (status === 'Open') return 'cyan'
      else if (status === 'Offered') return 'orange'
      else if (status === 'Accepted') return 'green'
      else return 'red'
    },
    getColorClass(status) {
      if (status === 'Open') return 'cyan--text'
      else if (status === 'Offered') return 'orange--text'
      else if (status === 'Accepted') return 'green--text'
      else return 'red--text'
    },
    getQuotes() {
      this.loading = true;
      let uri = '/api/quotes';
      this.$http.get(uri).then((response) => {
        this.data = response.data.data;
        this.loading = false;
      });
    },
  },
}
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>






